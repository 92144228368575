<template>
  <div
    id="case-index-container"
    class="h-full d-flex flex-column justify-start font-inter align-self-stretch pl-3 pt-3"
    fluid
  >
    <Loader v-if="loaderState" />
    <template v-else>
      <RunHeader
        :is-duplicate="runViewType == 'Duplicate'"
        :title="$t('testRuns')"
        :action-text="$t('testruns.createTestRun')"
        :active_label="$t('testruns.active')"
        :archived_label="$t('testruns.archived')"
        :btn_show="!isFilterArchived"
        :filter="filter"
        :active-item-count="activeItemCount"
        :archived-item-count="archivedItemCount"
        @update-filter="updateFilter"
      />
      <template>
        <v-container
          class="d-flex flex-column align-self-stretch"
          fluid
        >
          <div class="row">
            <div
              class="pa-0"
              :style="{ width: isProjectMenuCollapsed ? '5%' : '15%' }"
            >
              <div
                class="white card d-flex flex-column pa-3 rounded-lg mr-3 sticky-on-scroll"
                :class="isProjectMenuCollapsed ? 'justify-end' : 'justify-space-between'"
              >
                <div
                  v-if="!isProjectMenuCollapsed"
                  class="d-flex flex-column"
                >
                  <v-text-field
                    :placeholder="$t('search')"
                    background-color="#F9F9FB"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0 mb-3 flex-inherit"
                    height="38"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>

                  <v-btn
                    text
                    color="#667085"
                    class="text-capitalize rounded-lg bg-gray-theme justify-start mb-3"
                    width="100%"
                  >
                    {{ $t('testruns.all') }}
                  </v-btn>

                  <v-btn
                    text
                    color="#667085"
                    class="text-capitalize rounded-lg bg-gray-theme justify-start mb-3"
                    width="100%"
                  >
                    <div class="d-flex align-center">
                      <div class="mr-2">
                        <UnlinkedIcon />
                      </div>
                      <span>{{ $t('testruns.unlinked') }} 0</span>
                    </div>
                  </v-btn>

                  <v-btn
                    text
                    color="#0C2FF3"
                    class="text-capitalize rounded-lg bg-theme-primary-light justify-start mb-2"
                    width="100%"
                    @click="onToPlanExpanded"
                  >
                    <div class="d-flex justify-space-between w-full">
                      <div class="d-flex align-center">
                        <div class="mt-1 mr-2">
                          <LinkedIcon />
                        </div>
                        <span>{{ $t('testruns.toPlans') }} {{ plansCount }}</span>
                      </div>
                      <div>
                        <v-icon>
                          {{ isToPlanExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }} 
                        </v-icon>
                      </div>
                    </div>
                  </v-btn>

                  <div
                    v-if="isToPlanExpanded"
                    class="plan-list-wrapper"
                  >
                    <v-btn
                      v-for="(plan, index) in plans"
                      :key="index"
                      text
                      color="#667085"
                      class="text-capitalize btn-full font-weight-regular rounded-lg justify-start mb-2"
                      width="100%"
                    >
                      <div class="d-flex justify-between align-center w-full">
                        <div class="text-truncate">
                          {{ plan.name }}
                        </div> 
                        <div>(0)</div>
                      </div>    
                    </v-btn>
                  </div>
                  <div
                    v-else
                    class="plan-list-wrapper"
                  />
                </div>
                <div
                  v-if="!isProjectMenuCollapsed"
                  class="sticky-scroll"
                >
                  <div class="text-left">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t("createQuickPlan") }} 
                    </v-label>
                    <v-text-field
                      :placeholder="$t('enterName')"
                      height="38"
                      background-color="#F9F9FB"
                      class="field-theme mt-0 pt-1"
                    />
                  </div>
                  <v-btn
                    type="submit"
                    block
                    color="primary"
                    :depressed="true"
                    class="btn-theme"
                    width="100%"
                    height="40"
                  >
                    {{ $t("create") }}
                  </v-btn>
                </div>

                <div
                  class="collapse-btn btn-runs-sticky"
                  @click="toggleProjectMenu"
                >
                  <v-icon
                    class="collapse-icon"
                    color="#0C2FF3"
                  >
                    {{ isProjectMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
                    }}
                  </v-icon>
                  <span
                    v-if="!isProjectMenuCollapsed"
                    class="collapse-text"
                  >
                    {{ $t('collapse') }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="pa-0"
              :style="{ width: isProjectMenuCollapsed ? '95%' : '85%' }"
            >
              <div class="container pa-6 white align-start card rounded-lg container--fluid app-height-global">
                <template v-if="items.length > 0">
                  <v-row class="align-center">
                    <v-col
                      cols="6"
                      sm="6"
                    >
                      <div class="d-flex flex-row justify-start align-center">
                        <v-responsive
                          class="ma-0"
                          max-width="344"
                        >
                          <v-text-field
                            v-model="searchFilter"
                            :loading="loading"
                            class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                            :placeholder="$t('searchByName')"
                            height="40"
                            background-color="#F9F9FB"
                            hide-details
                          >
                            <template v-slot:prepend-inner>
                              <SearchIcon />
                            </template>
                          </v-text-field> 
                        </v-responsive>
                        <RunFilter 
                          :configurations="configurations" 
                          :milestones="milestones"  
                          :tags="tags"
                          @applyFilters="applyFilters"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="d-flex justify-end"
                    >
                      <v-menu
                        left
                        content-class="custom_ele rounded-lg shadow-theme"
                        :close-on-content-click="false"
                        min-width="191"
                        nudge-bottom="50"
                      >
                        <template v-slot:activator="{ on }">
                          <v-sheet
                            color="#F2F4F7"
                            width="40px"
                            class="d-flex align-center justify-center cursor-pointer v-sheet-theme"
                            height="40px"
                            rounded="lg"
                            :outlined="true"
                            v-on="on"
                          >
                            <GearIcon />
                          </v-sheet>
                        </template>
                        <v-list
                          width="191"
                          class="d-flex flex-column justify-space-between rounded-xl"
                        >
                          <h4 class="font-weight-regular text-start ml-4 mb-4 mt-2">
                            {{ $t('columns') }}
                          </h4>
                          <v-list-item
                            v-for="(header, index) in filteredMenuHeaders"
                            :key="index"
                            class="custom-menu-item"
                          >
                            <v-checkbox
                              v-model="header.isSelected"
                              :value="header.isSelected"
                              :true-value="true"
                              :false-value="false"
                              class="field-theme"
                              :ripple="false"
                              off-icon="icon-checkbox-off"
                              on-icon="icon-checkbox-on"
                              :size="16"
                              hide-details
                            >
                              <template v-slot:label>
                                <div class="custom-color-0c111d font-inter custom-text-14">
                                  {{ header.text }}
                                </div>
                              </template>
                            </v-checkbox>
                          </v-list-item>
                          <v-list-item
                            class="pl-5 mt-2 fw-semibold px-0 fs-14px"
                            :ripple="false"
                            :class="{ 'text-theme-primary': isColumnFilter,
                                      'text-theme-disabled': !isColumnFilter
                            }"
                            @click="handleColumnReset()"
                          >
                            {{ $t('Restore default') }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="fill-height pt-1"
                    >
                      <run-table
                        :filtered-headers="filteredHeaders"
                        :filtered-items="filteredRuns"
                        :item-key="itemKey"
                        :row-class="rowClass"
                        :clear-selection="clearSelection"
                        @edit-item="editItem"
                        @select-row="handleRowClick"
                        @select-item="setselected"
                        @archive-item="confirmArchiveRun"
                        @unarchive-item="confirmUnArchiveRun"
                        @delete-item="confirmDeleteRun"
                      />
                    </v-col>
                    <v-row
                      v-if="isSelected"
                      class="fill-height action-btn-wrapper"
                    >
                      <v-col cols="12">
                        <v-flex class="d-sm-flex justify-end">
                          <v-btn
                            v-if="isDuplicateRunViewType"
                            color="primary"
                            depressed
                            class="rounded-lg btn-theme text-capitalize"
                            width="100%"
                            max-width="141px"
                            :disabled="!hasOneSelectedItem"
                            height="40px"
                            @click="onClickDuplicate"
                          >
                            {{ $t('duplicate') }}
                          </v-btn>

                          <v-menu
                            v-if="!isDuplicateRunViewType"
                            v-model="menuOpen"
                            offset-y
                            top
                            right
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="primary"
                                :depressed="true"
                                class="btn-theme text-capitalize"
                                height="40"
                                :width="$vuetify.breakpoint.smAndDown ? '100%' : '141px'"
                                v-on="on"
                              >
                                {{ $t('testruns.create_testrun.actions') }}<v-icon>
                                  {{ menuOpen ? 'mdi-chevron-up' :
                                    'mdi-chevron-down' }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list
                              class="actions-list font-inter text-left"
                            >
                              <v-list-item @click="handleDuplicateAndApplyConfigDialog()">
                                <div class="d-flex align-center">
                                  <ApplyConfigIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.duplicateApplyConfig') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="filter == 'ongoing'"
                                @click="handleConfirmDialog('multi_archive')"
                              >
                                <div class="d-flex align-center">
                                  <ArchivedIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.archive') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-else-if="filter == 'archived'"
                                @click="handleConfirmDialog('multi_unarchive')"
                              >
                                <div class="d-flex align-center">
                                  <UnarchivedIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.unarchive') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="handleAddToMilestoneDialog()">
                                <div class="d-flex align-center">
                                  <MilestoneIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.addToMilestone') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="handleAddToTestPlansDialog()">                        
                                <div class="d-flex align-center">
                                  <CheckedIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.addToTestPlans') }}
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                class="red--text"
                                @click="handleConfirmDialog('multi_delete')"
                              >
                                <div class="d-flex align-center">
                                  <RemoveIcon />
                                </div>
                                <v-list-item-title class="pl-3">
                                  {{ $t('testruns.delete') }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-row>
                  
                  <v-dialog
                    v-model="showFormDialog"
                    max-width="50%"
                  >
                    <RunForm
                      :existing-run="selectedRun"
                      :is-loading="savingInProgress"
                      @update="updateTestRun"
                      @close="showFormDialog = false"
                    />
                  </v-dialog>
                </template>
                <template v-else>
                  <v-row class="py-sm-16">
                    <v-col
                      cols="12"
                      class="d-flex justify-center"
                    >
                      <v-img
                        :src="require('@/assets/png/auth-banner.png')"
                        alt="Table empty state"
                        width="100%"
                        max-width="438"
                      />
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-column align-center">
                        <p class="ma-0 font-weight-bold text-h6 text-sm-h5">
                          {{ $t('testruns.empty_state.title') }}
                        </p>
                        <p class="mb-0 mt-3">
                          {{ $t('testruns.empty_state.description_part1') }}
                        </p>
                        <p class="ma-0">
                          {{ $t('testruns.empty_state.description_part2') }}
                        </p>
                        <p class="ma-0">
                          {{ $t('testruns.empty_state.description_part3') }}
                        </p>
                      </div>
                      <v-flex class="mt-6 justify-center">
                        <v-btn
                          color="primary"
                          :depressed="true"
                          class="btn-theme text-capitalize rounded-lg"
                          height="40"
                          :to="{ name: 'TestRunCreate' }"
                        >
                          {{ $t('testruns.createTestRun') }} <v-icon
                            class="ml-1"
                            size="16"
                          >
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-col>
                  </v-row>
                </template>
              </div>
            </div>  
          </div>
        </v-container>
      </template>
    </template>
    <AddToMilestoneDialog
      :value="addToMilestoneDialog"
      :milestones="getMilestoneNames"
      @close="onCloseAddToMilestoneDialog"
    />
    <AddToTestPlansDialog
      :value="addToTestPlansDialog"
      :plans="getPlanNames"
      @close="onCloseAddToTestPlansDialog" 
    />
    <DuplicateAndApplyConfigDialog
      :value="duplicateAndApplyConfigDialog"
      :configurations="configurations"
      @close="onCloseDuplicateAndApplyConfigDialog"
    />
    <RunDiscardDialog
      v-model="showConfirmDialog"
      :title="ConfirmDialog_Title"
      :content="ConfirmDialog_Content"
      :content_part2="ConfirmDialog_Contentpart_two"
      :run_name="ConfirmDialog_RunName"
      :items="ConfirmDialog_Items"
      :type="ConfirmType"
      :btn_label="ConfirmDialog_btn_label"
      :color="ConfirmDialog_btn_color"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick"
    />
  </div>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import RunHeader from '@/components/TestRuns/RunHeader.vue';
import AddToMilestoneDialog from '@/components/TestRuns/AddToMilestoneDialog.vue';
import AddToTestPlansDialog from '@/components/TestRuns/AddToTestPlansDialog.vue';
import DuplicateAndApplyConfigDialog from '@/components/TestRuns/DuplicateAndApplyConfigDialog.vue';
import RunTable from '@/components/TestRuns/RunTable.vue';
import RunForm from './RunForm.vue';
import dayjs from 'dayjs';
import { mapGetters, mapState ,mapActions } from 'vuex';
import { runStateMap } from '@/constants/grid';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

import makeMilestonesService from '@/services/api/milestone'
import makePlanService from '@/services/api/plan'
import makeConfigurationService from '@/services/api/configuration' 
import makeTagService from '@/services/api/tag';

import { handleNetworkStatusError } from '@/mixins/redirect';
import Loader from '@/components/base/Loader.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import UnlinkedIcon from '@/assets/svg/unlinked.svg';
import LinkedIcon from '@/assets/svg/linked.svg';
import { sleep } from '@/utils/util';
import GearIcon from '@/assets/svg/gear.svg';
import ApplyConfigIcon from '@/assets/svg/duplicate-apply-config.svg';
import CheckedIcon from '@/assets/svg/checked.svg';
import MilestoneIcon from '@/assets/svg/milestone.svg';
import RemoveIcon from '@/assets/svg/remove.svg';
import ArchivedIcon from '@/assets/svg/archived.svg';
import UnarchivedIcon from '@/assets/svg/unarchive24px.svg';

export default { // TODO
  components: {
    RunForm,
    RunTable,
    RunHeader,
    RunFilter,
    RunDiscardDialog,
    AddToMilestoneDialog,
    AddToTestPlansDialog,
    DuplicateAndApplyConfigDialog,
    Loader,
    SearchIcon,
    GearIcon,
    ApplyConfigIcon,
    CheckedIcon,
    MilestoneIcon,
    RemoveIcon,
    ArchivedIcon,
    UnarchivedIcon,
    UnlinkedIcon,
    LinkedIcon
  },
  mixins: [handleNetworkStatusError],
  props: {
    runViewType: {
      type: String, 
      default: ''
    }
  },
  data() {
    return {
      appliedFilters: null,
      selectedRun: {},
      selectedStatus: Object.values(runStateMap)[0],
      savingInProgress: false,
      headers: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      expanded: [],
      searchFilter: '',
      menuOpen: false,
      addToMilestoneDialog: false,
      addToTestPlansDialog: false,
      duplicateAndApplyConfigDialog: false,
      showFormDialog: false,
      openedRow: undefined,
      loading: false,
      loaderState: false,
      checked: false,
      isColumnFilter: false,
      selecteditems: [],
      itemKey: 'uid',
      filter: 'ongoing',
      options: ['ongoing', 'archived'],
      clearSelection: false,
      isFilter: false,
      showConfirmDialog: false,
      ConfirmDialog_Title: '',
      ConfirmDialog_Content: '',
      ConfirmDialog_Contentpart_two: '',
      ConfirmDialog_Items: [],
      ConfirmDialog_btn_label: '',
      ConfirmDialog_btn_color: 'primary',
      ConfirmDialog_RunName: '',
      ConfirmType: '',
      isProjectMenuCollapsed: false,
      rowClass: () => 'project-item',
      isToPlanExpanded: false,
      plans: [],
      milestones: [],
      configurations: [],
      tags: [],
    };
  },
  computed: {
    ...mapState('run', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      runHeaders:'headers/runTableHeaders'
    }),
    plansCount() {
      return this.plans.length;
    },
    filteredHeaders() {
      this.updateHeaders({
        type: "run",
        headers: this.headers
      });
      const filtered = this.headers.filter((header) => header.isSelected);
      if (filtered.length < 9)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered
    },
    filteredRuns() {
      let filtered = this.runs;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    isDuplicateRunViewType() {
      return this.runViewType == 'Duplicate';
    },
    isSelected() {
      if (this.selecteditems.length > 0)
        return true
      return false
    },
    hasOneSelectedItem() {
      return this.selecteditems.length === 1;
    },
    runs() {
      let filtered = this.items.filter((item) => (item.archivedAt == ( this.filter === 'ongoing' ? null : item.archivedAt ? item.archivedAt : false )));
      return filtered.map((item) => {
        const createdAt = dayjs(item.createdAt).format('YYYY-MM-DD')
        
        return {
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
        createdAt
        }
      });
    },
    selectedProject() {
      return this.$route.params.key;
    },
    footerProps() {
      return {
        itemsPerPageOptions: [15, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        hideDefaultFooter: true,
      };
    },
    activeItemCount() {
      return this.items.filter((item) => item.archivedAt == null).length;
    },
    archivedItemCount() {
      return this.items.filter((item) => item.archivedAt).length;
    },
    isFilterArchived() {
      return this.filter === 'archived';
    },
    getMilestoneNames() {
      return this.milestones.map(milestone => milestone.name)
    },
    getPlanNames() {
      return this.plans.map(plan => plan.name)
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredRuns(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
    selectedProject() {
      this.getProjectRun(this.$route.params.handle);
    },
    searchFilter: {
      handler() {
        this.loading = true;
        this.$nextTick(() => {
          this.loading = false;
        });
      },
      immediate: true
    },
  },
  mounted() {
    this.init();
  },
   created() {
    this.initializeHeaders('run');
    this.headers = this.runHeaders;
    

  },
  methods: {
    // run actions
    ...mapActions("run",
    ['searchTestRun',
     'getAllTestRuns', 
     'updateTestRun',
     'updateTestRuns', 
     'deleteTestRun',
      'deleteTestRuns', 
      'duplicateTestRun']),
    // header accions
    ...mapActions("headers",
    ['initializeHeaders',
    'updateHeaders',
    'resetHeaders']),

    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    handleAddToMilestoneDialog() {
      this.addToMilestoneDialog = true;
    },
    onCloseAddToMilestoneDialog() {
      this.addToMilestoneDialog = false;
    },
    handleAddToTestPlansDialog() {
      this.addToTestPlansDialog = true;
    },
    onCloseAddToTestPlansDialog() {
      this.addToTestPlansDialog = false;
    },
    handleDuplicateAndApplyConfigDialog() {
      this.duplicateAndApplyConfigDialog = true;
    },
    onCloseDuplicateAndApplyConfigDialog() {
      this.duplicateAndApplyConfigDialog = false;
    },
    toggleProjectMenu(){
      this.isProjectMenuCollapsed = !this.isProjectMenuCollapsed
    },
    async init() {
      this.loaderState = false;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        let handle = this.$route.params.handle;
        await this.getProjectRun(handle); 
        this.getMilestones();
        this.getTestPlans();
        this.getConfigurations();
        this.getTags();
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test runs' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
        };
        hideLoader(); 
      }
    },
    onToPlanExpanded(){
      this.isToPlanExpanded = !this.isToPlanExpanded
    },
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.plans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
            const tagService = makeTagService(this.$api);
            try {
                const response = await tagService.getTags(handle);
                this.tags = response.data
            } catch (err) {
              showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
              return [];
            }
    }, 
  

    async handleConfirmDialog(type) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.ConfirmDialog_Title = this.$t('testruns.archive_dialog.title_multi');
          this.ConfirmDialog_Content = this.$t('testruns.archive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.archive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.ConfirmDialog_Title = this.$t('testruns.unarchive_dialog.title_multi');
          this.ConfirmDialog_Content = this.$t('testruns.unarchive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.unarchive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.ConfirmDialog_Title = this.$t('testruns.delete_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.delete_dialog.content');
          this.ConfirmDialog_Contentpart_two = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.ConfirmDialog_btn_label = this.$t('testruns.delete_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'danger';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_delete';
          break;
        case 'archive':
          this.ConfirmDialog_Title = this.$t('testruns.archive_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.archive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.archive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = this.selectedRun.name;
          this.ConfirmType = 'archive';
          break;
        case 'unarchive':
          this.ConfirmDialog_Title = this.$t('testruns.unarchive_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.unarchive_dialog.content');
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.unarchive_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'primary';
          this.ConfirmDialog_RunName = this.selectedRun.name;
          this.ConfirmType = 'unarchive';
          break;
        case 'delete':
          this.ConfirmDialog_Title = this.$t('testruns.delete_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.delete_dialog.content');
          this.ConfirmDialog_Contentpart_two = (this.filter == 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.ConfirmDialog_btn_label = this.$t('testruns.delete_dialog.btn_label');
          this.ConfirmDialog_btn_color = 'danger';
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'delete';
          break;
        case 'multi_milestone':
          this.ConfirmDialog_Title = this.$t('testruns.milestone_dialog.title');
          this.ConfirmDialog_Content = this.$t('testruns.milestone_dialog.content');
          this.ConfirmDialog_Items = await this.getMilestones();
          this.ConfirmDialog_Contentpart_two = '';
          this.ConfirmDialog_btn_label = this.$t('testruns.milestone_dialog.btn_label');
          this.ConfirmDialog_RunName = '';
          this.ConfirmType = 'multi_milestone';
          this.ConfirmDialog_btn_color = 'primary';
          break;
          case 'multi_testplan': 
            this.ConfirmDialog_Title = this.$t('testruns.testplan_dialog.title');
            this.ConfirmDialog_Content = this.$t('testruns.testplan_dialog.content');
            this.ConfirmDialog_Items = await this.getTestPlans();
            this.ConfirmDialog_Contentpart_two = '';
            this.ConfirmDialog_btn_label = this.$t('testruns.testplan_dialog.btn_label');
            this.ConfirmDialog_RunName = '';
            this.ConfirmType = 'multi_testplan';
            this.ConfirmDialog_btn_color = 'primary';
          break;

        
      }
    },
    handleConfirmBtnClick(type,items) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOneArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOneArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
        case 'multi_milestone':
          this.handleConfirmMilestoneClick(items)
          break;
        case 'multi_testplan':
          this.handleConfirmTestPlanClick(items)
        break;
      }
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedRun = [];
      this.selecteditems = [];
      this.ConfirmDialog_Items = [];
      this.clearSelection = true;
    },
    confirmArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('unarchive')
    },
    confirmDeleteRun(item) {
      this.selectedRun = item;
      this.handleConfirmDialog('delete')
    },
    editItem(item) {
      this.selectedRun = item;
      this.$router.push({
        name: 'TestRunEdit',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          id: item.uid,
          CustomItem: item, // TODO - don't pass items via the router
        },
      });
    },
    // Updates a test run
    handleConfirmOneArchiveClick(status) {
      const payload = {
        customFields: {
          ...this.selectedRun.customFields,
        },
        archived: status,
        externalId: this.selectedRun.externalId,
        source: this.selectedRun.source,
        name: this.selectedRun.name
      };
      this.updateTestRun({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        orgUid: '1',
        payload: payload
      }).then(() => {
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Test run' });
        this.refreshData()
      }).catch(error => {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'archiveError', { item: 'Test run' });
      });
      this.handleCloseClick()
    },
    // Updates multi test run
    handleConfirmArchiveClick(status) {
      var runs = []
      this.selecteditems.map((item) => {
        var run = {
          customFields: {
            ...item.customFields,
          },
          archived: status,
          externalId: item.externalId,
          source: item.source,
          name: item.name,
          uid: item.uid
        };
        runs = [...runs, run]
      })
      this.updateTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        payload: { runs: runs }
      }).then(() => {
        showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Test runs' });
        this.refreshData()
      }).catch(error => {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'archiveError', { item: 'Test runs' });
      });
      this.handleCloseClick()
    },
    // Delete a test run
    handleConfirmDeleteClick() {
      this.deleteTestRun({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: this.selectedRun.uid,
        perPage: 9999,
        currentPage: 0,
      }).then(() => {
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Test run' });
        this.refreshData()
      }).catch(error => {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'Test run' });
      });
      this.handleCloseClick()
    },
    async handleConfirmMilestoneClick(items){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const milestoneService = makeMilestonesService(this.$api)
      const runIds = this.selecteditems.map(item => item.uid) 
      const payload = {
        runIds
      }
      if(items.length) {
        for (const element of items) {
          try {
            await milestoneService.addRunsToMilestone(handle, projectKey, element, payload);
            showSuccessToast(this.$swal, 'addSuccess', { item: 'Test runs to milestone' });
          } catch (error) {
            this.redirectOnError(error.response.status);
            showErrorToast(this.$swal, 'addError', { item: 'Test runs to milestone' });
          }
        }
      }
      this.handleCloseClick()
    },
    async handleConfirmTestPlanClick(items){
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const testPlanService = makePlanService(this.$api);
      const runIds = this.selecteditems.map(item => item.uid) 
      const payload = {
        runs: runIds
      }

      if(items.length) {
        for (const element of items) {
          try {
            await testPlanService.addRunsToTestPlan(handle, projectKey, element, payload);
            showSuccessToast(this.$swal, 'addSuccess', { item: 'Test runs to test plan' });
          } catch (error) {
            this.redirectOnError(error.response.status);
            showErrorToast(this.$swal, 'addError', { item: 'Test runs to test plan' });
          }
        }
      }
      this.handleCloseClick();
    },
    // Delete multi test run
    handleConfirmDeleteMultiClick() {
      var runIds = []
      this.selecteditems.map((item) => {
        runIds = [...runIds, item.uid]
      })
      this.deleteTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        payload: { runIds: runIds },
      }).then(() => {
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Test runs' });
        this.refreshData()
      }).catch(error => {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'Test runs' });
      });
      this.handleCloseClick()
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
      matchApplyFilter(item) {
      // Check if appliedFilters are not empty before checking each filter
      if (this.appliedFilters?.panel_priority.length > 0 && 
          !this.appliedFilters.panel_priority.includes(item.priority)) {
        return false;
      }
      if (this.appliedFilters?.panel_status.length > 0 && 
          !this.appliedFilters.panel_status.includes(item.status)) {
        return false;
      }
      if (this.appliedFilters?.panel_milestone.length > 0 && 
          !this.appliedFilters.panel_milestone.includes(item.customFields.milestone) && 
          !this.appliedFilters.panel_milestone.includes('None')) {
        return false;
      }

      if (this.appliedFilters?.panel_tag.length > 0 && 
          !this.appliedFilters.panel_tag.includes(item.customFields.tags) && 
          !this.appliedFilters.panel_tag.includes('none')) {
        return false;
      }
      if (this.appliedFilters?.testcases_range[0] > item.customFields.caseCount || 
          this.appliedFilters?.testcases_range[1] < item.customFields.caseCount) {
        return false;
      }
      if (this.appliedFilters?.progress_range[0] > item.customFields.progress || 
          this.appliedFilters?.progress_range[1] < item.customFields.progress) {
        return false;
      }
      if (this.appliedFilters?.dateRange.start && this.appliedFilters?.dateRange.start > item.createdAt || 
          this.appliedFilters?.dateRange.end && this.appliedFilters?.dateRange.end < item.createdAt) {
        return false;
      }
      return true;
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();
      return item.name.toLowerCase().includes(lowerCaseFilter)
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    // Refreshes all data
    async refreshData() {
      let handle = this.currentAccount.handle;
      this.getProjectRun(handle);
    },
    deleteTestCase(uid) {
      this.$store.dispatch(
        'run/deleteTestRun',
        {
          swal: this.$swal,
          uid,
          handle: this.$route.params.handle,
          projectKey: this.$route.params.key,
          perPage: this.perPage,
          currentPage: this.currentPage,
        },
        { root: true }
      );
    },
    // Fetches test runs within the selected project
    async getProjectRun(handle) {
      await this.getAllTestRuns({ handle: handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
    },
    // Converts timestamp to local time
    convertToLocal(timestamp) {
      return dayjs(timestamp).format('ddd, MMM DD hh:mm A');
    },
    // Determines the type of test
    determineType(test) {
      return test.source === 'pinata' ? 'Exploratory' : 'Manual';
    },
    // Searches for test runs
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.$route.params.handle,
          projectKey: this.$route.params.key,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    handleColumnReset() {
      this.resetHeaders('run');
      this.headers.map((header) => { header.isSelected = true })
    },
    async onClickDuplicate(){
      if(this.isDuplicateRunViewType && this.hasOneSelectedItem){
        this.SET_ACTION_TYPE('duplicate');
        const item = this.selecteditems[0];
        await this.duplicateTestRun(item);
        this.$router.push({
          name: 'RunAddCase',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            CustomCases: item?.customFields?.testcases 
          }
        });
      }
    },
    // handle row edit
    handleRowEdit(item) {
      this.selectedRun = item;
      this.showFormDialog = true;
    },
    // Handles row click
    async handleRowClick(item) {
      if (this.runViewType == 'Duplicate') {

        await this.duplicateTestRun(item);
        this.$router.push({
          name: 'RunAddCase',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            CustomCases: item.customFields.testcases // TODO - don't pass via router
          }
        });
        
      }
      else {
        this.$router.push({
          name: 'TestRunCaseEdit',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
            id: item.uid,
            CustomCases: item.customFields.testcases, // TODO don't pass via router
            progress: item.customFields.progress,
            name: item.name
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.custom-runtable {
  color: #344054 !important;
}

.custom-color-0c111d {
  color: #0C111D !important;
}

.custom-color-d0d5dd {
  color: #D0D5DD !important;
}

.custom-color-061AAE {
  color: #061AAE !important;
}
.custom-menu-item {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  align-items: center;
}

.custom-text-12 {
  font-size: 12px;
}

.custom-text-14 {
  font-size: 14px;
}

.h-full {
  height: 100%;
  min-height: 100%;
}
.custom-swal-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.custom-swal-title {
  text-align: left;
  width: 100%;
}
.custom-swal-content {
  text-align: left;
  width: 100%;
}
.custom-swal-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.custom-swal-confirm-button,
.custom-swal-cancel-button {
  margin-left: 10px;
}
.action-btn-wrapper {
  position: sticky;
    bottom: 0;
    background-color: white;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    z-index: 8;
}
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 6px);
}
.sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-bottom: 5rem;
  background: #fff;
    z-index: 1;
    padding-top: 1rem;
}
.plan-list-wrapper {
  height: calc(100vh - 28rem);
  overflow: auto;
  scrollbar-width: thin;
}
.btn-runs-sticky {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  justify-content: flex-start;
  z-index: 9;
}

</style>
